<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>ユーザー編集</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <FormRow :required="true">
                      <template v-slot:label>ユーザー名</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.nameSei }"
                                type="text"
                                name="nameSei"
                                placeholder="姓"
                                v-maxlength
                                v-space
                                maxlength="20"
                                v-model="form.nameSei"
                              />
                            </div>
                            <div class="form-group-item">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.nameMei }"
                                type="text"
                                name="nameMei"
                                placeholder="名"
                                v-space
                                v-maxlength
                                maxlength="20"
                                v-model="form.nameMei"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>ID</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-5">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.cpmUserId }"
                                type="text"
                                name="cpmUserId"
                                v-trim
                                v-maxlength
                                maxlength="20"
                                v-model="form.cpmUserId"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow>
                      <template v-slot:label>初回パスワード</template>
                      <template v-slot:subLabel><span class="text-note">10文字以上の半角英数字および記号（/ \ ¥ を除く）すべての組み合わせ</span></template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-5">
                              <div class="form-password">
                                <input
                                  v-trim
                                  v-model="form.password"
                                  :class="{ 'form-control form-password-input': true, 'is-error': errors.password }"
                                  :type="pwdVisible ? 'text' : 'password'"
                                  name="password"
                                />
                                <label class="form-check form-password-toggle">
                                  <input class="form-check-input" type="checkbox" v-model="pwdVisible" />
                                  <i class="aikon form-password-icon"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ul class="listGrid">
              <li class="listGrid-item">
                <button class="btn btn-red" type="button" @click="showModal('deletedModal')">削除</button>
              </li>
              <li class="listGrid-item">
                <button class="btn btn-yellow" type="button" @click="showModal('pauseModal')">{{ cpmUserDetail.status ? '一時停止' : '再開' }}</button>
              </li>
            </ul>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: 'AccountUserCpm' }">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-main" :handle-submit="edit" button-text="編集する" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('deletedModal')" v-if="deletedModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">
          対象のアカウントを削除しますか？
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="closeModal('deletedModal')"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-red"
              :handle-submit="deleteCpmUser"
              button-text="削除する"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('pauseModal')" v-if="pauseModal">
      <template v-slot:headline>{{ cpmUserDetail.status ? '一時停止' : '再開' }}</template>
      <template v-slot:body>
        <p class="description text-align-center">
          {{
            cpmUserDetail.status
              ? '一時停止し、このユーザーアカウントのログインを止めますか？'
              : '一時停止を解除し、このユーザーアカウントのログインを再開しますか？'
          }}
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-yellow"
              :handle-submit="changeStatus"
              :button-text="cpmUserDetail.status ? '一時停止にする' : '再開する'"
            />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
//mixins
import cpmUser from '@/mixins/module/cpmUser';
import modal from '@/mixins/plugin/modal';
//helpers
import { isAlphanumSpecialWithMinLength, isAlphanumWithMaxLength, notSpace } from '@/helpers/validators';
//component
import FormRow from '@/components/FormRow.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'AccountUserCpmEdit',
  data: function() {
    return {
      pageName: 'アカウント設定',
      deletedModal: false,
      pauseModal: false,
      form: {
        nameSei: '',
        nameMei: '',
        cpmUserId: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      cpmUserDetail: 'settingUserCpm/cpmUserDetail'
    })
  },
  components: {
    FormRow,
    Modal
  },
  validations() {
    let form = {
      nameSei: { required, maxLength: maxLength(20), notSpace },
      nameMei: { required, maxLength: maxLength(20), notSpace },
      cpmUserId: { required, alphanumWithMaxLength: isAlphanumWithMaxLength(20) },
      password: { alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(10) }
    }
    if (!this.form.password) delete form.password;
    return { form };
  },
  mixins: [cpmUser, modal],
  methods: {
    async edit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'inforCpm');
      } else {
        this.errors = {};
        let data = {
          cpmUserId: this.form.cpmUserId,
          password: this.form.password,
          nameSei: this.form.nameSei,
          nameMei: this.form.nameMei,
        };
        if (!this.form.password) delete data.password;
        const result = await this.dispatchAction('settingUserCpm/updateCpmUser', { data: data });
        if (result) {
          await this.$router.push({ name: 'AccountUserCpm' });
          this.$message.updated('accountInfo');
        }
      }
    },
    async deleteCpmUser() {
      const res = await this.dispatchAction('settingUserCpm/deleteCpmUser', this.id);
      this.closeModal('deletedModal');
      if (res) {
        await this.$router.push({ name: 'AccountUserCpm' });
        this.$message.deleted('account');
      }
    },
    async changeStatus() {
      const result = await this.dispatchAction('settingUserCpm/updateStatus', { status: !this.cpmUserDetail.status ? 1 : 0 });
      this.closeModal('pauseModal');
      if (result) {
        this.getCpmUserDetail();
        if (this.cpmUserDetail.status) this.$message.showSuccess('activeUser');
        else this.$message.showSuccess('pauseUser');
      }
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    await this.getCpmUserDetail(),
    this.form = { ...this.form, ...this.cpmUserDetail }
    this.$loading.clear(loading);
  },
};
</script>
